import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Table } from 'react-bootstrap';

import '../../generic-page.scss';

const CSPVEGearingPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>PVE Gearing</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_gearing.png"
            alt="PVE Gearing"
          />
        </div>
        <div className="page-details">
          <h1>PVE Gearing</h1>
          <h2>A simple guide about gearing in PVP.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          This is a generic PVE gearing guide, meant for explaining the
          terminology used in our PVE gearing recommendations for characters.
        </p>
        <p>
          This guide is by no means exhaustive and should only be used as a
          reference point. Specialised gear sets that are best used for
          specialised content (such as Danger Close) will not be covered here.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
        <SectionHeader title="PVE Gearing" />
        <p>There are five main kinds of PvE gear:</p>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_1.webp"
          alt="PVP Gear"
        />
        <h5>CDR DPS</h5>
        <ul>
          <li>Uses only Maze gear pieces for best performance</li>
          <li>Set - CDR</li>
          <li>
            Substats - Always aim for Skill Haste on second line (First line
            subs are locked on Maze Gears)
          </li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_4.webp"
          alt="PVP Gear"
        />
        <h5>Defensive CDR</h5>
        <ul>
          <li>Set - CDR</li>
          <li>
            Substats - Always aim for Skill Haste on second line, and
            Anti-Melee/Anti-Ranged/Anti-Ground/Anti-Soldier/Anti-Mech/Anti-Counter
            Damage Res on the first line (depending on the enemy type you are
            fighting)
          </li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_1.webp"
          alt="PVP Gear"
        />
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_2.webp"
          alt="PVP Gear"
        />
        <h5>Autoattack DPS</h5>
        <ul>
          <li>
            Can use a variety of different pieces, such as Maze, Britra Relics,
            and Spectral gear
          </li>
          <li>Set - ATK, ASPD, or Any Spectral set</li>
          <li>
            Substats - Anti-Ground Damage,
            Anti-Striker/Anti-Defender/Anti-Ranger/Anti-Sniper/Crit Damage
            (depending on the enemy type you are fighting)
          </li>
        </ul>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_3.webp"
          alt="PVP Gear"
        />
        <h5>Full Tank</h5>
        <ul>
          <li>Set - HP</li>
          <li>
            Inhibitor Weapons are preferred because they are the only weapons in
            the game which can roll Anti-Ground Damage Res
          </li>
          <li>
            Substats - As much Anti-Ground Dmg Res as possible, and at least 50%
            CDMG Res
          </li>
        </ul>
        <h5>CDR/ASPD Tank</h5>
        <ul>
          <li>Set - CDR/ASPD set</li>
          <li>
            nhibitor Weapons are preferred because they are the only weapons
            which can roll Anti-Ground Damage Res
          </li>
          <li>
            Substats - Aim for as much Anti-Ground Damage Res as possible.
            Remaining substats will vary greatly depending on a unit’s kit.
          </li>
          <li>
            This is a niche set which is only used in certain scenarios and on a
            select few units.
          </li>
          <li>
            Note: CDR Tank focuses more on tanking, while Defensive CDR focuses
            more on having more Skill Haste
          </li>
        </ul>
        <SectionHeader title="Relic Gear" />
        <p>
          This section deals with the five kinds of Relic T7 gear you can get
          in-game, and what they are best used for.{' '}
        </p>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_5.png"
          alt="PVP Gear"
        />
        <h5>Britra</h5>
        <p>Mainly used as autoattack DPS based gear. </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Useful Latent</th>
              <th>Set</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ranged Damage</td>
              <td>
                ASPD, ATK (Anti-XX sets can be used depending on scenario)
              </td>
              <td>Usable on all ranged DPS units.</td>
            </tr>
            <tr>
              <td>Melee Damage</td>
              <td>
                ASPD, ATK (Anti-XX sets can be used depending on scenario)
              </td>
              <td>Usable on all melee DPS units.</td>
            </tr>
            <tr>
              <td>ATK%</td>
              <td>
                ASPD, ATK (Anti-XX sets can be used depending on scenario)
              </td>
              <td>Usable on all DPS units in general. </td>
            </tr>
            <tr>
              <td>CDMG%</td>
              <td>
                ASPD, ATK (Anti-XX sets can be used depending on scenario)
              </td>
              <td>
                Generally only useful on units who are able to always crit, like
                Kestrel Xiao Lin.
              </td>
            </tr>
          </tbody>
        </Table>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_6.png"
          alt="PVP Gear"
        />
        <h5>Inhibitor</h5>
        <p>
          Used as tank gear. Evasion Accessories are SIGNIFICANTLY better than
          Hit ones.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Useful Latent</th>
              <th>Set</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CDMG Res</td>
              <td>HP</td>
              <td>Great option for helping you hit a total of 50% CDMG Res.</td>
            </tr>
            <tr>
              <td>Anti-Ranged Damage Res</td>
              <td>HP</td>
              <td>
                Generally only useful on units who will be consistently damaged
                at range.{' '}
              </td>
            </tr>
            <tr>
              <td>Anti-Melee Damage Res</td>
              <td>HP</td>
              <td>Useful on most frontline tanks. </td>
            </tr>
            <tr>
              <td>HP%</td>
              <td>HP</td>
              <td>
                Great latent choice if your unit is already capped on both
                Anti-XX Res and has at least 50% CDMG Res.
              </td>
            </tr>
          </tbody>
        </Table>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_7.png"
          alt="PVP Gear"
        />
        <h5>Swift</h5>
        <p>
          Mainly used for extremely high ASPD builds, or for Defensive CDR
          builds.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Useful Latent</th>
              <th>Set</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ASPD</td>
              <td>ASPD</td>
              <td>
                Useful only on units whose kits scale immensely well on ASPD.{' '}
              </td>
            </tr>
            <tr>
              <td>Skill Haste (Only for Accessories)</td>
              <td>CDR</td>
              <td>
                Swift Accessories are the only Accessories in the game which can
                have both Skill Haste and Anti-Ground Damage Res subs at the
                same time, making them uniquely useful for Defensive CDR builds.{' '}
              </td>
            </tr>
            <tr>
              <td>ATK%/CDMG%</td>
              <td>
                ASPD, ATK (Anti-XX sets can be used depending on scenario)
              </td>
              <td>
                Usable on all DPS units in general, but generally worse than
                Britra Relics.{' '}
              </td>
            </tr>
          </tbody>
        </Table>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_8.webp"
          alt="PVP Gear"
        />
        <h5>Jungle</h5>
        <p>Used for certain frontliners, healers, or other unique units.</p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Useful Latent</th>
              <th>Set</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Incoming Healing</td>
              <td>Incoming Healing, Melee/Ranged/Ground DMG Res</td>
              <td>Can be BIS on self-healing units such as Mors.</td>
            </tr>
            <tr>
              <td>Healing Enhancement</td>
              <td>Healing Enhancement</td>
              <td>
                Good on healers who do not rely on Skill Haste, such as Acolyte.
              </td>
            </tr>
            <tr>
              <td>ASPD</td>
              <td>Healing Enhancement</td>
              <td>Another good option for Acolytes.</td>
            </tr>
            <tr>
              <td>SPD</td>
              <td>Speed</td>
              <td>Pretty much just Maestra gear.</td>
            </tr>
          </tbody>
        </Table>
        <StaticImage
          src="../../../images/counterside/generic/pvpgear_9.webp"
          alt="PVP Gear"
        />
        <h5>Volcano</h5>
        <p>Used mostly for DPS units.</p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Useful Latent</th>
              <th>Set</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Anti-Counter/Anti-Soldier/Anti-Mech Damage</td>
              <td>Anti-Ground Damage</td>
              <td>
                Good for stacking Anti-XX subs against a certain enemy unit
                type.
              </td>
            </tr>
            <tr>
              <td>ATK%/ASPD/CDMG</td>
              <td>Anti-Ground Damage</td>
              <td>
                Good latents for generic DPS usage. CDMG is less useful on units
                that do not have guaranteed crit.
              </td>
            </tr>
            <tr>
              <td>Any DPS oriented latent, really</td>
              <td>Defense Penetration</td>
              <td>
                Only useful if you are unable to get a significant amount of
                Defense shred on a high Defense enemy, for whatever reason.
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </DashboardLayout>
  );
};

export default CSPVEGearingPage;

export const Head: React.FC = () => (
  <Seo
    title="PVE Gearing | Counter Side | Prydwen Institute"
    description="A simple guide about gearing in PVE."
    game="cs"
  />
);
